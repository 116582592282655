<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('preaching_points.edit')">
        <actions
          slot="actions"
          crud-links="preachings"
          controller="PreachingPoints"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :preaching="preaching"
          :loading="loading"
          :disabled="true"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>

  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'preachings-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      preaching: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `preaching-points/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const preachingId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(preachingId))
      } catch (err) {
        // console.log('Error fetching preaching data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.preaching = { ...u.data.data }
      var code = this.preaching.code
      var coder = code.substring(0, 5)
      this.preaching.team = { code: coder }
    },
    async submit (preaching) {
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(preaching.id), preaching)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
